.dashboard-container {
    padding: 20px;
    background-color: #f0f0f0;
    font-family: 'Arial', sans-serif;
  }
  
  .organization-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .organization-logo {
    width: 150px;
    margin-bottom: 20px;
  }
  
  .search-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .search-container input {
    width: 80%;
    max-width: 500px;
    padding: 8px;
    font-size: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .member-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr 1fr 1fr 2fr 1fr;
    gap: 8px;
    padding: 8px;
    border-bottom: 1px solid #ddd;
    overflow: hidden;
  }
  
  .member-row.header {
    font-weight: bold;
    background-color: #e0e0e0;
  }
  
  .member-row.header div {
    display: flex;
    align-items: center;
  }
  
  .member-row.header div::after {
    content: '▲▼'; /* Neutral indicator for sortable columns */
    font-size: 0.7em;
    margin-left: 4px;
    color: #aaa;
    display: none; /* Hide neutral indicators by default */
  }
  
  .member-row.header div.sortable::after {
    display: inline; /* Show neutral indicators for sortable columns */
  }
  
  .member-row.header div.sorting-asc::after {
    content: '▲';
    color: #000;
  }
  
  .member-row.header div.sorting-desc::after {
    content: '▼';
    color: #000;
  }
  
  .member-row {
    display: contents;
    font-size: 13px; /* Reduced font size by 1pt */
  }
  
  .member-row div,
  .member-row input,
  .member-row button {
    padding: 4px;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Add ellipsis to overflow text */
  }
  
  .member-row input {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 4px;
    font-size: 13px; /* Reduced font size by 1pt */
    max-width: 100%;
  }
  
  .update-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 4px 8px;
    cursor: pointer;
    font-size: 13px; /* Reduced font size by 1pt */
  }
  
  .update-button:hover {
    background-color: #0056b3;
  }
  
  .update-button[disabled] {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  footer {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    color: #777;
  }
  