.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    color: #3d413e;
  }
  
  .login-logo {
    width: 300px;
    margin-bottom: 20px;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 300px;
    margin: 20px 0;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .input-group button {
    width: 100%;
    padding: 10px 20px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .input-group button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: red;
  }
  
  footer {
    margin-top: 20px;
    font-size: 12px;
    color: #666;
  }
  